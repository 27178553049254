import { lazy } from 'react';
import Layout from '../components/Layout';
import Loadable from '../components/Loadable';
import RequireAuth from '../components/RequireAuth';

const LazyHorseRidingList = Loadable(lazy(() => import('../pages/HorseRideList/List')));

const Payments = {
    path: '/horse-riding',
    element: (
        <RequireAuth>
            <Layout />
        </RequireAuth>
    ),
    children: [
        {
            path: '/horse-riding',
            element: <LazyHorseRidingList />,
        },
    ],
};

export default Payments;
