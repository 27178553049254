import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionEntity } from '../entities/SubscriptionEntity';
import { SUBSCRIPTION_FREE_PLAN, SUBSCRIPTION_NOT_ACTIVE_STATUSES } from '../config/consts';

interface SubscriptionSlice {
    limits: {
        data: SubscriptionEntity | null;
        isActive: boolean;
        isCreated: boolean;
        horseLimit: number;
        userLimit: number;
        subscriptionId?: string;
    };
    sms: {
        data: SubscriptionEntity | null;
        isActive: boolean;
        isCreated: boolean;
        subscriptionId?: string;
    };
}

const initialState: SubscriptionSlice = {
    limits: {
        subscriptionId: undefined,
        data: null,
        isActive: false,
        isCreated: false,
        horseLimit: SUBSCRIPTION_FREE_PLAN.horseLimit,
        userLimit: SUBSCRIPTION_FREE_PLAN.userLimit,
    },
    sms: {
        subscriptionId: undefined,
        data: null,
        isActive: false,
        isCreated: false,
    },
};

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        setSubscription: (state, action: PayloadAction<Array<SubscriptionEntity> | null>) => {
            if (!action.payload) {
                return;
            }

            action.payload.forEach((subscription) => {
                if (
                    subscription.subscriptionItems.some((item) => item.id === 'horseLimit' || item.id === 'userLimit')
                ) {
                    state.limits.data = subscription;

                    state.limits.subscriptionId = subscription.subscriptionID;
                    state.limits.isCreated = true;
                    state.limits.isActive = !SUBSCRIPTION_NOT_ACTIVE_STATUSES.includes(subscription.status);

                    if (state.limits.isActive) {
                        state.limits.horseLimit =
                            subscription.subscriptionItems.find((item) => item.id === 'horseLimit')?.quantity ||
                            SUBSCRIPTION_FREE_PLAN.horseLimit;
                        state.limits.userLimit =
                            subscription.subscriptionItems.find((item) => item.id === 'userLimit')?.quantity ||
                            SUBSCRIPTION_FREE_PLAN.userLimit;
                    } else {
                        state.limits.horseLimit = SUBSCRIPTION_FREE_PLAN.horseLimit;
                        state.limits.userLimit = SUBSCRIPTION_FREE_PLAN.userLimit;
                    }
                } else if (
                    subscription.subscriptionItems.some((item) => ['100sms', '500sms', '1000sms'].includes(item.id))
                ) {
                    state.sms.data = subscription;
                    state.sms.subscriptionId = subscription.subscriptionID;
                    state.sms.isCreated = true;
                    state.sms.isActive = !SUBSCRIPTION_NOT_ACTIVE_STATUSES.includes(subscription.status);
                }
            });
        },
    },
});

export const { setSubscription } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
