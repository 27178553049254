import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs, { Dayjs } from 'dayjs';

interface ScheduleSlice {
    hasEvents: boolean;
    action: 'all' | 'futured' | null;
    currentDay: Dayjs;
}

const initialState: ScheduleSlice = {
    hasEvents: false,
    action: null,
    currentDay: dayjs(),
};

export const scheduleSlice = createSlice({
    name: 'schedules',
    initialState,
    reducers: {
        setHasEventsState: (state, action: PayloadAction<boolean>) => {
            state.hasEvents = action.payload;
        },
        setAction: (state, action: PayloadAction<'all' | 'futured' | null>) => {
            state.action = action.payload;
        },
        setCurrentDay: (state, action: PayloadAction<Dayjs>) => {
            state.currentDay = action.payload;
        },
    },
});

export const { setHasEventsState, setAction, setCurrentDay } = scheduleSlice.actions;

export default scheduleSlice.reducer;
